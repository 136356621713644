body {
  font-family: Roboto;
}

.all-unset {
  all: unset !important;
}

.csv-input {
  border-radius: 5px;
  padding: 9px;
  width: 112px;
  border-radius: 5px;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0;
}

.react-csv-input {
  display: none;
}

.table {
  margin-top: 15px;
  padding: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  padding: 0 30px;
}

.content .content-child {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.camera {
  position: relative;
  z-index: 8;
}

.footer {
  position: absolute;
  left: 20px;
  bottom: 10px;
  z-index: 10;
}

.result {
  margin-top: 50px;
}

.result p {
  font-size: 18px;
}

.admitted {
  align-self: flex-start;
  color: red;
}

.manual {
  color: red;
  margin-top: 20px !important;
  margin-bottom: 50px !important;
  font-family: bold !important;
}

#dashboard {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.autoRefresh {
  display: flex;
  align-items: center;
}

.pt20 {
  padding-top: 20px !important;
}

.mb20 {
  margin-bottom: 20px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
}

.hidden {
  display: none;
}

.attend {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 10px;
}


@media print {
  body {
    font-family: "Roboto", sans-serif;
  }
}